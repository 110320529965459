import ThreatSearchBar from '../search/ThreatSearchBar';
import ThreatDeck from './ThreatDeck';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const ThreatDealer = ({ threats, updateDeck, isWaitingDeck, noSearchBar }) => {
  return (
    <Box>
      {!noSearchBar ? (
        <ThreatSearchBar updateThreats={updateDeck} />
      ) : (
        <></>
      )}
      <Box mt={2}>
        {isWaitingDeck ? (
          <CircularProgress />
        ) : (
          <ThreatDeck threats={threats} />
        )}
      </Box>
    </Box>
  );
};

export default ThreatDealer;
