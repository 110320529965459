import TitleBox from './TitleBox';

import Typography from '@mui/material/Typography';
import CameraIcon from '@mui/icons-material/Camera';

const HeadTitleBox = (props) => {
  const { sx, style } = props;
  return (
    <TitleBox
      sx={{ ...sx }}
      style={{ ...style }}
      mb={2}
      Icon={ <CameraIcon sx={{ color: "sky.main" }} /> }
    >
      <Typography
        style={{ fontSize: "1.5rem", fontWeight: "bold" }}
      >
        { props.children }
      </Typography>
    </TitleBox>
  );
};

export default HeadTitleBox;
