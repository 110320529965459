import Landing from './landing/Landing';
import Threat from './threat/Threat';
import ThreatGuide from './threat/Guide';
import ThreatConcept from './threat/Concept';
import ThreatAsset from './threat/Asset';
import ThreatFuture from './threat/Future';
import Contact from './contact/Contact';
import Test from './test/Test';
import TestLanding from './test/Landing';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { createTheme, ThemeProvider, useMediaQuery } from '@mui/material';
import { alpha } from '@mui/material/styles';

const islBase = '#0D0D59';
const skyblue = '#0d6efd';

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = createTheme({
    palette: {
      // mode: prefersDarkMode ? 'dark' : 'light'
      // mode: 'light'
      primary: {
        main: islBase,
        light: alpha(islBase, 0.5),
        dark: alpha(islBase, 0.9),
      },
      sky: {
        main: skyblue,
        light: alpha(skyblue, 0.5),
        dark: alpha(skyblue, 0.9)
      }
    },
    typography: {
      fontFamily: '"Noto Sans JP", sans-serif'
    }
  });
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          {/* <Route exact path='/' element={<Landing />} /> */}
          <Route path='/' element={<Threat />} />
          <Route path='/threat' element={<Threat />} />
          {/* <Route path='/threat/guide' element={<ThreatGuide />} /> */}
          <Route path='/threat/concept' element={<ThreatConcept />} />
          <Route path='/threat/asset' element={<ThreatAsset />} />
          <Route path='/threat/future' element={<ThreatFuture />} />
          {/* <Route path='/contact' element={<Contact />} /> */}
          {/* <Route path='/test' element={<Test />} /> */}
          {/* <Route path='/test/landing' element={<TestLanding />} /> */}
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
