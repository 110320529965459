import ResponsiveDrawer from '../util/drawer/ResponsiveDrawer';

import { useState } from 'react';
import { Link } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import AttributionIcon from '@mui/icons-material/Attribution';
import InfoIcon from '@mui/icons-material/Info';
import WebAssetIcon from '@mui/icons-material/WebAsset';
import ExploreIcon from '@mui/icons-material/Explore';
import ContactsIcon from '@mui/icons-material/Contacts';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import SchoolIcon from '@mui/icons-material/School';
import Toolbar from '@mui/material/Toolbar';

const InboundLink = [
  {
    title: 'ホーム',
    Icon: HomeIcon,
    link: '/'
  },
  {
    title: '実証実験',
    Icon: AttributionIcon,
    link: undefined,
    child: [
      {
        title: '使い方',
        Icon: InfoIcon,
        link: '/threat/guide'
      },
      {
        title: '見える化',
        Icon: WebAssetIcon,
        link: '/threat/asset'
      },
      // {
      //   title: 'Concept',
      //   Icon: ExploreIcon,
      //   link: '/threat/concept'
      // }
    ]
  }
];
const OutboundLink = [
  {
    title: '研究室活動',
    Icon: NoteAltIcon,
    link: 'https://blog.isl.im.dendai.ac.jp/'
  },
  {
    title: 'TDU',
    Icon: SchoolIcon,
    link: 'https://dendai.ac.jp'
  }
];

const Arena = ({ arenaWidth, title, Contents }) => {
  const [key, setKey] = useState('');
  const handleClick = (title) => {
    if (key === title) {
      setKey(undefined);
    } else {
      setKey(title);
    }
  };

  const drawerContentsConstructor = ({ originalDrawerContents, outbound=false }) => {
    const construct = (contents, level) => {
      return contents.map(({ title, Icon, link, child }) => {
        if (child === undefined) {
          return (
            <ListItem sx={{ pl: 4*level }} key={title} disablePadding>
              <ListItemButton component={Link} to={link} target={outbound ? '_blank' : '_self'}>
                <ListItemIcon>
                  <Icon />
                </ListItemIcon>
                <ListItemText primary={title} />
              </ListItemButton>
            </ListItem>
          );
        } else {
          return (
            <>
              <ListItem sx={{ pl: 4*level }} key={title} disablePadding>
                <ListItemButton onClick={() => handleClick(title)} target={outbound ? '_blank' : '_self'}>
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText primary={title} />
                  {key === title ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
              </ListItem>
              <Collapse in={key === title} timeout='auto' unmountOnExit>
                <List component='div'>
                  {construct(child, level+1)}
                </List>
              </Collapse>
            </>
          );
        }
      });
    };

    return construct(originalDrawerContents, 0);
  }

  const drawerContents = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {drawerContentsConstructor({ originalDrawerContents: InboundLink })}
      </List>
      <Divider />
      <List>
        {drawerContentsConstructor({ originalDrawerContents: OutboundLink, outbound: true })}
      </List>
    </div>
  );

  return (
    <ResponsiveDrawer drawerWidth={arenaWidth} title={title} drawerContents={drawerContents} MainContents={Contents} />
  );
}

export default Arena;
