const execute = async (url, params) => {
  const resJson = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  })
  .then(async (res) => {
    return await res.json();
  })
  .catch((err) => {
    throw err;
  })

  return resJson;
}

const executeAndRetry = async (url, params, retryURL) => {
  return await execute(url, params)
  .catch(async(err) => {
    return await execute(retryURL, params);
  });
}

const getThreats = async (params={}) => {
  const primaryURL = '/api/threats';
  const secondaryURL = 'https://172.18.0.46/api/threats';
  return await executeAndRetry(primaryURL, params, secondaryURL )
};

const getIPNames = async (params={}) => {
  const primaryURL = '/api/threats/ipnames';
  const secondaryURL = 'https://172.18.0.46/api/threats/ipnames';
  return await executeAndRetry(primaryURL, params, secondaryURL)
};

const getAccessScale = async (params={}) => {
  const primaryURL = '/api/access/landing'
  const secondaryURL = 'https://172.18.0.46/api/access/landing'
  return await executeAndRetry(primaryURL, params, secondaryURL)
};

const getMaliciousAccessScale = async (params={}) => {
  const primaryURL = '/api/access/malicious';
  const secondaryURL = 'https://172.18.0.46/api/access/malicious';
  return await executeAndRetry(primaryURL, params, secondaryURL)
};

export { getThreats, getIPNames, getAccessScale, getMaliciousAccessScale };
