import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// const Search = styled('div')(({ theme }) => ({
//   position: 'relative',
//   borderRadius: theme.shape.borderRadius,
//   backgroundColor: alpha(theme.palette.primary.main, 0.15),
//   '&:hover': {
//     backgroundColor: alpha(theme.palette.primary.main, 0.25),
//   },
//   marginLeft: 0,
//   width: '100%',
//   [theme.breakpoints.up('sm')]: {
//     marginLeft: theme.spacing(1),
//     width: 'auto',
//   },
// }));

// const SearchIconWrapper = styled('div')(({ theme }) => ({
//   padding: theme.spacing(0, 2),
//   height: '100%',
//   position: 'absolute',
//   pointerEvents: 'none',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// }));

const SearchBarButton = ({ handleOpen }) => {
  const theme = useTheme();
  return (
    <Button
      sx={{ padding: 0, width: "100%", height: "100%", textTransform: "none", textAlign: "left" }}
      onClick={handleOpen}
    >
      <Box
        p={1}
        sx={{ width: "100%", height: "100%", borderRadius: 2 }}
        style={{
          backgroundColor: alpha(theme.palette.sky.light, 0.15),
          "&:hover": {
            backgroundColor: alpha(theme.palette.sky.light, 0.25)
          }
        }}
      >
        <Box
          sx={{ width: "100%", height: "100%", display: "flex", alignItems: "center" }}
        >
          <Box
            pr={1}
            pb={0.5}
            // sx={{ width: "20%" }}
            style={{ fill: theme.palette.primary.light }}
          >
            <SearchIcon />
          </Box>
          <Box
            pb={0.5}
            sx={{ width: "100%", borderBottom: "1px solid #000" }}
          >
            <Typography>
              Search...
            </Typography>
          </Box>
        </Box>
      </Box>
    </Button>
  );
};

export default SearchBarButton;
