import Arena from './Arena';
import Arena2 from './Arena2';

const ArenaWidth = 240;
const Title = '東京電機大学　情報セキュリティ研究室(Information Security Lab.)'

const Origin = ({ Core }) => {
  // return (
  //   <Arena arenaWidth={ArenaWidth} title={Title} Contents={Core} />
  // );
  return (
    <Arena2 Contents={Core} />
  )
};

export default Origin;
