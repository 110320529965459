import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DetailsIcon from '@mui/icons-material/Details';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const TITLE = 'OSコマンドインジェクション';
const DESCRIPTION = '「OSコマンドインジェクション」は、Webサーバ上の任意のOSコマンドが実行されてしまう問題です。これにより、Webサーバを不正に操作され、重要情報などが盗まれたり、攻撃の踏み台に悪用される場合があります。';
const REFERENCE_LINKS = [
  {
    title: '詳しく知りたい',
    Icon: DetailsIcon,
    link: 'https://www.ipa.go.jp/security/vuln/websecurity/os-command.html'
  },
  {
    title: '対策',
    Icon: InfoIcon,
    link: 'https://www.ipa.go.jp/security/vuln/websecurity/os-command.html#:~:text=%E6%A0%B9%E6%9C%AC%E7%9A%84%E8%A7%A3%E6%B1%BA,-2%2D(i)%20%E3%82%B7%E3%82%A7%E3%83%AB'
  }
];

const OSCommandInjectionTipsModalTipsModal = () => {
  return (
    <Box>
      <Typography component='div'>
        <Box mt={2} mb={2} fontSize='h5.size' fontWeight='fontWeightBold'>
          {TITLE}
        </Box>
      </Typography>
      <Divider />
      <Typography variant='body1'>
        <Box mt={2} mb={2} fontSize='h6.size'>
          {DESCRIPTION}
        </Box>
      </Typography>
      <Divider />
      <List>
        {REFERENCE_LINKS.map(({ title, Icon, link }) => (
          <ListItem key={title}>
            <ListItemButton component={Link} to={link} target='_blank'>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )
};

export default OSCommandInjectionTipsModalTipsModal;
