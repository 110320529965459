import { useState } from 'react';
import Box from '@mui/material/Box';
import SearchBarButton from '../../../util/search/SearchBarButton';
import ThreatSearchModal from '../modal/ThreatSearchModal';

const ThreatSearchBar = ({ updateThreats }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Box
      position='sticky'
      top='75px'
      sx={{ display: "flex", justifyContent: "flex-end", zIndex: 1 }}
    >
      <Box
        sx={{ width: "260px", height: "50px" }}
      >
        <SearchBarButton handleOpen={handleOpen} />
      </Box>
      <ThreatSearchModal open={open} handleClose={handleClose} updateThreats={updateThreats} />
    </Box>
  );
};

export default ThreatSearchBar;
