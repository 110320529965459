import Origin from '../origin/Origin';
import AssetCore from './core/AssetCore';

const Deck = () => {
  return (
    <Origin Core={AssetCore} />
  );
};

export default Deck;
