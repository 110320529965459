import Box from '@mui/material/Box';

const BodyBox = (props) => {
  return (
    <Box
      sx={{ width: '100%' }}
    >
      <Box
        maxWidth="1000px"
        ml="auto"
        mr="auto"
        mb={2}
        pl={1}
        pr={1}
      >
        <Box
          maxWidth="800px"
          ml="auto"
          mr="auto"
        >
          {props.children}
        </Box>
      </Box>
    </Box>
  );
};

export default BodyBox;
