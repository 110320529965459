import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DetailsIcon from '@mui/icons-material/Details';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const TITLE = 'クロスサイトスクリプティング';
const DESCRIPTION = '「クロスサイトスクリプティング」は、Webサイトの訪問者の入力をそのまま画面に表示する掲示板などのWebアプリケーションが、悪意のあるスクリプト（命令）を訪問者のブラウザに送ってしまう問題です。これにより、アンケート、掲示板、サイト内検索など、ユーザからの入力内容をWebページに表示するWebアプリケーションで、適切なセキュリティ対策がされていない場合、悪意を持ったスクリプト(命令)を埋め込まれてしまい、Webページを表示した訪問者のブラウザ環境でスクリプトが実行されてしまう可能性があります。その結果として、cookieなどからの情報の漏洩や意図しないページの参照が行われてしまいます。';
const REFERENCE_LINKS = [
  {
    title: '詳しく知りたい',
    Icon: DetailsIcon,
    link: 'https://www.ipa.go.jp/security/vuln/websecurity/cross-site-scripting.html'
  },
  {
    title: '対策',
    Icon: InfoIcon,
    link: 'https://www.ipa.go.jp/security/vuln/websecurity/cross-site-scripting.html#:~:text=%E5%AF%BE%E7%AD%96%E3%81%AB%E3%81%A4%E3%81%84%E3%81%A6,-%E3%82%AF%E3%83%AD%E3%82%B9%E3%82%B5%E3%82%A4%E3%83%88%E3%83%BB%E3%82%B9%E3%82%AF%E3%83%AA%E3%83%97%E3%83%86%E3%82%A3%E3%83%B3%E3%82%B0'
  }
];

const XSSTipsModal = () => {
  return (
    <Box>
      <Typography component='div'>
        <Box mt={2} mb={2} fontSize='h5.size' fontWeight='fontWeightBold'>
          {TITLE}
        </Box>
      </Typography>
      <Divider />
      <Typography variant='body1'>
        <Box mt={2} mb={2} fontSize='h6.size'>
          {DESCRIPTION}
        </Box>
      </Typography>
      <Divider />
      <List>
        {REFERENCE_LINKS.map(({ title, Icon, link }) => (
          <ListItem key={title}>
            <ListItemButton component={Link} to={link} target='_blank'>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )
};

export default XSSTipsModal;
