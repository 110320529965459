import Origin from '../origin/Origin';
import FutureCore from './core/FutureCore';

const Future = () => {
  return (
    <Origin Core={FutureCore} />
  );
};

export default Future;
