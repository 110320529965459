import TitleBox from './TitleBox';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TimelineIcon from '@mui/icons-material/Timeline';

const Header = (props) => {
  return (
    <Box
      sx={{ width: "100%", height: "200px", mt: 4, mb: 4, display: "flex", justifyContent: "center" }}
      style={{ position: "relative", overflow: "hidden" }}
    >
      <img
        src="/trons/base12.jpg"
        style={{ width: "100%", height: "200px", objectFit: "cover" }}
      />
      <Box
        style={{ position: "absolute", width: "100%", maxWidth: "1000px", top: "40px", left: 0, right: 0, margin: "auto", paddingLeft: "1rem", color: "#fafafa" }}
      >
        { props.children }
      </Box>
      <Box
        style={{ position: "absolute", width: "100%", maxWidth: "1000px", top: "110px", left: 40, right: 0, margin: "auto", paddingLeft: "1rem", color: "#0d6efd" }}
      >
        <Box
          className='fadein'
          style={{ opacity: 1, transition: "opacity 2s ease" }}
        >
          <TitleBox
            Icon={ <TimelineIcon /> }
          >
            <Typography
              fontWeight='bold'
            >
              実証実験中
            </Typography>
          </TitleBox>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
