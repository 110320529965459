import Header from '../../common/Header';
import BodyBox from '../../common/BodyBox';
import HeadTitleBox from '../../common/HeadTitleBox';
import Speech from '../../common/Speech';
import ListTitleBox from '../../common/ListTitleBox';
import KnowledgeListBox from '../../common/KnowledgeListBox';
import ImageFigure from '../../common/ImageFigure';
import ThreatCasino from '../comps/casino/ThreatCasino';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Threat = () => {
  return (
    <>
      <Header>
        <Typography
          style={{ fontSize: "0.8rem", fontWeight: "bold" }}
        >
          ISL(Information Security Lab.)
        </Typography>
        <Typography
          style={{ fontSize: "1.2rem", fontWeight: "bold" }}
        >
          東京電機大学 情報セキュリティ研究室
        </Typography>
      </Header>
      <BodyBox>
        <Box
          mb={6}
        >
          <HeadTitleBox>
            {/* Webアクセスの見える化によるサイバー攻撃抑止の実証実験 */}
            攻撃的アクセスの見える化によるサイバー攻撃抑止の実証実験
          </HeadTitleBox>
          <Box>
            <Speech mb={4}>
              {/* 情報セキュリティ研究室は「Webアクセスの見える化によるサイバー攻撃抑止」の実証実験を推進しています。
              研究室への攻撃的なWebアクセス(SQLインジェクションなど)を特定・見える化し「①サイバー攻撃の抑止」「②第三者に悪用されている機器の特定・救済」に活用していきます。 */}
              情報セキュリティ研究室は「攻撃的アクセスの見える化によるサイバー攻撃抑止」の実証実験を推進しています。\
              多くの組織と一丸となり、攻撃的なアクセスを見える化し対策していくことで、「①サイバー攻撃の抑止」「②第三者に悪用されている機器の特定・救済」を目指していきます。
            </Speech>
            {/* <Box
              mb={3}
            >
              <ListTitleBox>
                研究室のWebサイト(= このWebサイト)のログから、研究室への攻撃的アクセスを特定
              </ListTitleBox>
              <Speech>
                IPAの「iLogScanner」を用いて「SQLインジェクション」や「OSコマンドインジェクション」などの攻撃的アクセスを特定します。
              </Speech>
            </Box>
            <Box>
              <ListTitleBox>
                研究室のWebサイト(= このWebサイト)で見える化
              </ListTitleBox>
              <Speech>
                研究室への攻撃的アクセスを見える化し、サイバー攻撃の抑止や、第三者に悪用されている機器の特定・救済を推進していきます。
              </Speech>
            </Box> */}
            <Box mb={4}>
              <ImageFigure src="/exs/ex1_2.jpg" />
            </Box>
            <KnowledgeListBox
              to='/threat/future'
              inner={ true }
            >
              実証実験の構想; 構想から実装、今後の検討点などを紹介しています。
            </KnowledgeListBox>
          </Box>
        </Box>
        <Box>
          <HeadTitleBox>
            攻撃的アクセスの公開
          </HeadTitleBox>
          <Speech mb={2}>
            私たちは、このWebサイトへの攻撃的なアクセスをiLogScannerを用いて特定し、このWebサイト自身で公開しています。\
            下では、実際に私たちが受けた攻撃的アクセスから最新の3件のみの情報を表示しています。
          </Speech>
          <Box mb={4}>
            <KnowledgeListBox
              to='/threat/future'
              inner={ true }
            >
              実証実験の構想; 攻撃的アクセスを特定し公開する狙いなどを紹介しています。
            </KnowledgeListBox>
            <KnowledgeListBox
              to='/threat/asset'
              inner={ true }
            >
              攻撃的アクセスの一覧; 私たちがこれまで受けた、すべての攻撃的アクセスを検索して確認できます。
            </KnowledgeListBox>
          </Box>
          <ThreatCasino params={{ limit: '3' }} noSearchBar={ true } />
        </Box>
      </BodyBox>
    </>
  );
};

export default Threat;
