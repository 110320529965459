import ThreatCard from './ThreatCard';

import Box from '@mui/material/Box';

const ThreatDeck = ({ threats }) => {
  return (
    <Box
      // sx={{ display: "flex", flexWrap: "wrap", gap: "10px", width: "100%" }}
      sx={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, 260px)", justifyContent: "center", gridGap: "10px" }}
      mt={1}
      mb={1}
    >
      {threats.map((threat, index) => (
        <ThreatCard index={index} threat={threat} />
      ))}
    </Box>
  );
};

export default ThreatDeck;
