import { getThreats } from "../../util/fetch";
import ThreatDealer from "./ThreatDealer";

import { useEffect, useState } from 'react';

const ThreatCasino = ({ params={}, noSearchBar=false }) => {
  const [threats, setThreats] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    (async () => {
      setThreats(await getThreats(params=params));
      setIsLoading(false);
    })();
  }, []);

  return (
    <ThreatDealer
      threats={threats}
      updateDeck={async (params) => {
        setIsLoading(true);
        setThreats(await getThreats(params=params));
        setIsLoading(false);
      }}
      isWaitingDeck={isLoading}
      noSearchBar={noSearchBar}
    />
  );
};

export default ThreatCasino;
