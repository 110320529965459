import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DetailsIcon from '@mui/icons-material/Details';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const TITLE = 'SQLインジェクション';
const DESCRIPTION = '「SQLインジェクション」は、データベースと連携したWebアプリケーションに問合せ命令文の組み立て方法に問題があるとき、Webアプリケーションへ宛てた要求に、悪意を持って細工されたSQL文を埋め込まれて（Injection）しまうと、データベースを不正に操作されてしまう問題です。これにより、データベースが不正に操作され、Webサイトは重要情報などが盗まれたり、情報が書き換えられたりといった被害を受けてしまう場合があります。';
const REFERENCE_LINKS = [
  {
    title: '詳しく知りたい',
    Icon: DetailsIcon,
    link: 'https://www.ipa.go.jp/security/vuln/websecurity/sql.html'
  },
  {
    title: '対策',
    Icon: InfoIcon,
    link: 'https://www.ipa.go.jp/security/vuln/websecurity/sql.html#:~:text=%E6%A0%B9%E6%9C%AC%E7%9A%84%E8%A7%A3%E6%B1%BA,-1%2D(i)%2Da'
  }
];

const SQLInjectionTipsModal = () => {
  return (
    <Box>
      <Typography component='div'>
        <Box mt={2} mb={2} fontSize='h5.size' fontWeight='fontWeightBold'>
          {TITLE}
        </Box>
      </Typography>
      <Divider />
      <Typography variant='body1'>
        <Box mt={2} mb={2} fontSize='h6.size'>
          {DESCRIPTION}
        </Box>
      </Typography>
      <Divider />
      <List>
        {REFERENCE_LINKS.map(({ title, Icon, link }) => (
          <ListItem key={title}>
            <ListItemButton component={Link} to={link} target='_blank'>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={title} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )
};

export default SQLInjectionTipsModal;
