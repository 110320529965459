import Origin from '../origin/Origin';
import ThreatCore from './core/ThreatCore';

const Threat = () => {
  return (
    <Origin Core={ThreatCore} />
  );
};

export default Threat;
