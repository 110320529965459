import ThreatDetailModal from '../modal/ThreatDetailModal';
import HeadTitleBox from '../../../common/HeadTitleBox';
import Speech from '../../../common/Speech';
import ResponsiveModal from '../../../common/ResponsiveModal';

import { useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CloseIcon from '@mui/icons-material/Close';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LaunchIcon from '@mui/icons-material/Launch';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const ThreatCard = ({ index, threat, isDemo=false }) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [accessY, accessHMS] = threat.access_clock.split('; ');
  const [accessHM, accessS] = [accessHMS.substr(0, 5), accessHMS.substr(5, 3)];

  const ThreatRequestModalContent = () => {
    return (
      <>
        <Box width='100%' pb={6}>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <IconButton onClick={handleClose} >
              <CloseIcon style={{ fontSize: "2rem" }} />
            </IconButton>
          </Box>
          <Box mb={4}>
            <HeadTitleBox>攻撃的アクセスの種類</HeadTitleBox>
            <Speech>{ threat.attack_method }</Speech>
          </Box>
          <Box>
            <HeadTitleBox>攻撃的アクセスの内容</HeadTitleBox>
            <Speech style={{ wordBreak: 'break-all' }}>{ threat.request }</Speech>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Box
      sx={{ position: "relative" }}
    >
      <Card
        sx={{ width: "260px", height: "420px" }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", width: "260px", height: "50px", margin: "auto", background: "#0D0D59" }}
        >
          <Box
            ml={1}
            mr={1}
          >
            <LocationOnIcon style={{ fill: "#fafafa" }} />
          </Box>
          <Typography
            style={{ fontSize: "1.78rem", color: "#fafafa" }}
          >
            {threat.client_ip}
          </Typography>

        </Box>
        <CardContent>
          <Box
            mb={3}
            style={{ cursor: "default" }}
          >
            <Typography
              style={{ fontFamily: "Time New Roman", fontSize: "3.0rem", textAlign: "center", lineHeight: "1.1", color: "#0D0D59" }}
            >
              {accessHM}<span style={{ fontSize: "1rem" }}>{accessS}</span>
            </Typography>
            <Typography
              style={{ fontFamily: "Time New Roman", fontWeight: "bold", fontSize: "0.8rem", textAlign: "center", color: "#0D0D59" }}
            >
              {accessY}
            </Typography>
          </Box>
          <Box>
            <Box
              mb={1}
              sx={{ width: "100%" }}
            >
              <Button
                sx={{ padding: 0, width: "100%" }}
                component={Link}
                to={`https://shodan.io/host/${threat.client_ip}`}
                target='_blank'
              >
                <Box
                  p={1}
                  sx={{ width: "100%", borderRadius: 2 }}
                  style={{ backgroundColor: "rgba(108, 117, 125, 0.2)" }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: 2 }}
                  >
                    <Box
                      sx={{ width: "160px" }}
                    >
                      <Box
                        mb={0.3}
                        sx={{ display: "flex", alignItems: "center", width: "100%" }}
                      >
                        <Box
                          mr={1}
                        >
                          <LocationOnIcon style={{ fontSize: "1.2rem", color: "#0D0D59" }} />
                        </Box>
                        <Typography
                          style={{ fontSize: "1.1rem", fontWeight: "bold" }}
                        >
                          {threat.client_ip}
                        </Typography>
                      </Box>
                      <Box>
                      <Typography
                        style={{ fontSize: "0.7rem" }}
                      >
                        攻撃的なアクセス元の場所や<br />機器の状態を確認できます。
                      </Typography>

                      </Box>
                    </Box>
                    <Box>
                      <LaunchIcon />
                    </Box>
                  </Box>
                </Box>
              </Button>
            </Box>
            <Box
              mb={1}
              p={1}
              sx={{ width: "100%", borderRadius: 2 }}
              style={{ backgroundColor: "rgba(108, 117, 125, 0.2)" }}
            >
              <Box
                sx={{ display: "flex", alignItems: "center", width: "100%", height: "100%" }}
              >
                <Box
                  mr={1}
                >
                  <HeartBrokenIcon style={{ fontSize: "1.2rem", color: "#0D0D59" }} />
                </Box>
                <Typography
                  style={{ fontSize: "0.9rem", fontWeight: "bold", color: "#0D0D59" }}
                >
                  {threat.attack_method}
                </Typography>
              </Box>
            </Box>
            <Box
              mb={1}
              sx={{ width: "100%" }}
            >
              <Button
                sx={{ padding: 0, width: "100%", textTransform: "none" }}
                component={Link}
                onClick={handleOpen}
              >
                <Box
                  p={1}
                  sx={{ width: "100%", borderRadius: 2 }}
                  style={{ backgroundColor: "rgba(108, 117, 125, 0.2)" }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", width: "100%", height: "100%" }}
                  >
                    <Box
                      mr={1}
                    >
                      <VisibilityIcon style={{ fontSize: "1.2rem", color: "#0D0D59" }} />
                    </Box>
                    <Typography
                      style={{ fontSize: "0.9rem", fontWeight: "bold", overflow: "hidden", whiteSpace: "nowrap", wordBreak: "break-all", textOverflow: "ellipsis" }}
                    >
                      {threat.request}
                    </Typography>
                  </Box>
                </Box>
              </Button>
            </Box>
          </Box>
        </CardContent>
        <Box
            sx={{ position: "absolute", bottom: 0, width: "100%", height: "1rem", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "#0D0D59" }}
        >
          <MoreHorizIcon style={{ position: "relative", fill: "#fafafa", fontSize: "1rem" }} />
        </Box>
      </Card>
      {/* <ThreatDetailModal open={open} handleClose={handleClose} index={index} threat={threat} isDemo={isDemo} /> */}
      <ResponsiveModal open={open} handleClose={handleClose} ModalContent={ ThreatRequestModalContent } />
    </Box>
  );
};

export default ThreatCard;
