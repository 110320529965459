import Header from '../../common/Header';
import BodyBox from '../../common/BodyBox';
import HeadTitleBox from '../../common/HeadTitleBox';
import Speech from '../../common/Speech';
import ListTitleBox from '../../common/ListTitleBox';
import KnowledgeListBox from '../../common/KnowledgeListBox';
import ImageFigure from '../../common/ImageFigure';
import ThreatCasino from '../comps/casino/ThreatCasino';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LaunchIcon from '@mui/icons-material/Launch';

const ShareCore = () => {
  return (
    <>
      <Header>
        <Typography style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
          攻撃的アクセスの一覧
        </Typography>
      </Header>
      <BodyBox>
        <Box mb={5}>
          <HeadTitleBox>
            攻撃的アクセスの一覧
          </HeadTitleBox>
          <Box mb={4}>
            <Box mb={2}>
              <Speech>
                私たちは「①サイバー攻撃の抑止」「②第三者に悪用されている機器の特定・救済」を掲げ、私たちが受けた攻撃的アクセスを特定し、カードの形式で公開しています。
              </Speech>
              <Speech>
                このページでは、検索でカードを絞り込んで攻撃的アクセスの帰属情報を確認することができます。
              </Speech>
            </Box>
            <KnowledgeListBox
              to='/threat/future'
              inner={ true }
              sx={{ marginBottom: 2 }}
            >
              実証実験の構想; 実証実験の構想や、攻撃的アクセスを特定し公開する狙いなどを紹介しています。
            </KnowledgeListBox>
            <ImageFigure src='/exs/ex5_3.jpg' mb={4} />
          </Box>
        </Box>
        <ThreatCasino />
      </BodyBox>
    </>
  )
};

export default ShareCore;
