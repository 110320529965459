import Origin from '../origin/Origin';
import ConceptCore from './core/ConceptCore';

const Concept = () => {
  return (
    <Origin Core={ConceptCore} />
  );
};

export default Concept;
