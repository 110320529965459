import Box from '@mui/material/Box';

const TitleBox = ( props ) => {
  const { sx, style, mr, mb, ml, mt, Icon } = props;
  return (
    <Box
      sx={sx}
      style={{ display: "flex", alignItems: "center", ...style }}
      mr={mr}
      mb={mb}
      ml={ml}
      mt={mt}
    >
      <Box
        mr={1}
      >
        { Icon }
      </Box>
      { props.children }
    </Box>
  );
}

export default TitleBox;
