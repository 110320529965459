import Box from '@mui/material/Box';

const ImageFigure = (props) => {
  const {pr, pb, pl, pt, mr, mb, ml, mt, src} = props;
  return (
    <Box
      pr={pr} pb={pb} pl={pl} pt={pt}
      mr={mr} mb={mb} ml={ml} mt={mt}
    >
      <img
        src={ src }
        style={{ width: "100%", objectFit: "cover" }}
      />
    </Box>
  );
};

export default ImageFigure;
