import SpringModal from "../util/modal/SpringModal";
import { useIsMobile } from "./isMobile";

const ResponsiveModal = ({ open, handleClose, ModalContent, style }) => {
  const isMobile = useIsMobile();
  const modalWidth = "600px";
  const defaultStyle = {
    top: "50%",
    left: isMobile ? "0" : "50%",
    transform: isMobile ? "translate(0, -50%)" : "translate(-50%, -50%)",
    width: isMobile ? "calc(100% - 1rem)" : `calc(${modalWidth} - 1rem)`,
    marginLeft: "0.5rem", marginRight: "0.5rem",
    paddingTop: 1,
    paddingBottom: 1
  };

  return (
    <SpringModal open={ open } handleClose={ handleClose } ModalContent={ ModalContent } style={{ ...defaultStyle, ...style }} />
  );
};

export default ResponsiveModal;
